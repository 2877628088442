* {
  transition: all 0.1s ease-in-out;
}
@media (min-width: 1000px) {

  
/* HERE I DONT EXPLAIN A THING.*/
    .outercon {
        margin-top: 2%;
        display: flex;
        justify-content: center;
    }
    .containerabout {
        margin-top: 15%;
        background: linear-gradient(to bottom, rgba(32, 32, 32, 0.178), rgba(22, 22, 22, 0.233));
        border: 1px solid rgba(162, 241, 252, 0.5);
        border-radius: 10px;
        overflow: hidden;
        width: 400px;
        height: 550px;
        position: relative;
        box-shadow: 0 0 30px rgb(0, 0, 0);

    }
    
    .banner {
        width: 100%;
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .bannerpic {
        width: 100%;
        height: 100px;
        object-fit: cover;
    }
    
    .profile {
        text-align: center;
        padding: 20px;
        position: relative;
        z-index: 1;
    }
    
    .avatar {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 1px solid rgb(162, 241, 252);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        position: relative;
        margin-top: 40px; 
        margin-right: 55%;

    }
    
    .info {
        padding: 5%;
        border-radius: 10px;
        margin-top: 20px;
        background-color: rgba(22, 22, 22, 0.555);
        height: 350px;
    }
    
    .username {
        color: rgb(162, 242, 252);
        font-size: 18px;
        font-weight: bold;
        margin-right: 55%;

    }
    
    .badges {
        position: absolute;
        margin: -12% 0 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(19, 19, 19, 0.747);
        border-radius: 7px;
        padding: 5px;
    }
    

    .badges img {
        width: 20px;
        height: 20px;
        margin: 0 5px;

    }

    
    .bio {
        margin: 10px;
        font-size: 24px;
        color: #d0d0d0;
        text-align: start;
    }
    


      .social-container {
        margin-top: 150px;
        border-radius: 20px;
        background-color: #0000005b;
        padding: 0.5%;
      }
      ul {
        list-style: none;
      }
      
      .socials {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .socials .icon-content {
        left: 10%;
        transform: translateX(-100%);
        margin: 0 10px;
        position: relative;
      }

      .socials .icon-content .tooltip {
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        padding: 0px 10px;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        font-size: 14px;
        transition: all 0.3s ease;
      }
      .socials .icon-content:hover .tooltip {
        opacity: 1;
        visibility: visible;
        top: -50px;
      }
      .socials .icon-content .link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        color: #c2c2c2;
        background-color: #1b1b1b96;
        transition: all 0.3s ease-in-out;
      }
      .socials .icon-content .link:hover {
        box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
      }
      .socials .icon-content .link svg {
        width: 30px;
        height: 30px;
      }
      .socials .icon-content .link[data-social="spotify"]:hover {
        color: #1db954;
      }
      .socials .icon-content .link[data-social="discord"]:hover {
        color: #7299ee;
      }
      .socials .icon-content .link[data-social="steam"]:hover {
        color: #2a475e;
      }







      

}

@media (max-width: 1000px) {
/* HERE I DONT EXPLAIN A THING.*/
.outercon {
    margin-top: 2%;
    display: flex;
    justify-content: center;
}
.containerabout {
  margin-top: 15%;
  background: linear-gradient(to bottom, rgba(32, 32, 32, 0.178), rgba(22, 22, 22, 0.233));
  border: 1px solid rgba(162, 241, 252, 0.5);
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  height: 450px;
  position: relative;
  box-shadow: 0 0 30px rgb(0, 0, 0);

}

.banner {
    width: 100%;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.bannerpic {
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.profile {
    text-align: center;
    padding: 20px;
    position: relative;
    z-index: 1;
}

.avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid rgb(162, 241, 252);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-top: 40px; 
    margin-right: 55%;

}

.info {
    padding: 5%;
    border-radius: 10px;
    margin-top: 20px;
    background-color: rgba(22, 22, 22, 0.555);
    height: 240px;
}

.username {
    color: rgb(162, 242, 252);
    font-size: 18px;
    font-weight: bold;
    margin-right: 55%;

}

.badges {
    position: absolute;
    margin: -12% 0 0 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(19, 19, 19, 0.747);
    border-radius: 7px;
    padding: 5px;
}


.badges img {
    width: 15px;
    height: 15px;
    margin: 0 5px;

}


.bio {
    margin: 10px;
    font-size: 16px;
    color: #d0d0d0;
    text-align: start;
}
.social-container {
  margin-top: 90px;
  border-radius: 20px;
  background-color: #0000005b;
  padding: 0.5%;
}
ul {
  list-style: none;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
}
.socials .icon-content {
  left: 5%;
  transform: translateX(-100%);
  margin: 0 10px;
  position: relative;
}
.socials .icon-content .tooltip {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 0px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}
.socials .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}
.socials .icon-content .link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #c2c2c2;
  background-color: #1b1b1b96;
  transition: all 0.3s ease-in-out;
}
.socials .icon-content .link:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}
.socials .icon-content .link svg {
  width: 30px;
  height: 30px;
}
.socials .icon-content .link[data-social="spotify"]:hover {
  color: #1db954;
}
.socials .icon-content .link[data-social="discord"]:hover {
  color: #7299ee;
}
.socials .icon-content .link[data-social="steam"]:hover {
  color: #2a475e;
}

@media (max-width: 768px) {
  .containerabout {
  margin-top: 15%;
  background: linear-gradient(to bottom, rgba(32, 32, 32, 0.178), rgba(22, 22, 22, 0.233));
  border: 1px solid rgba(162, 241, 252, 0.5);
  border-radius: 10px;
  overflow: hidden;
  width: 200px;
  height: 350px;
  position: relative;
  box-shadow: 0 0 30px rgb(0, 0, 0);

}
  .badges {
    position: absolute;
    margin: -12% 0 0 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(19, 19, 19, 0.747);
    border-radius: 7px;
    padding: 5px;
}

.info {
  padding: 5%;
  border-radius: 10px;
  margin-top: 20px;
  background-color: rgba(22, 22, 22, 0.555);
  height: 150px;
}
.badges img {
    width: 15px;
    height: 15px;
    margin: 0 5px;

}
  .social-container {
    margin-top: 5px;
    border-radius: 20px;
    background-color: #0000005b;
    padding: 0.1%;
  }
  ul {
    list-style: none;
  }
  
  .socials {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .socials .icon-content {
    left: 5%;
    transform: translateX(-100%);
    margin: 0 10px;
    position: relative;
  }
  .socials .icon-content .tooltip {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    padding: 0px 10px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    font-size: 14px;
    transition: all 0.3s ease;
  }
  .socials .icon-content:hover .tooltip {
    opacity: 1;
    visibility: visible;
    top: -50px;
  }
  .socials .icon-content .link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: #c2c2c2;
    background-color: #1b1b1b96;
    transition: all 0.3s ease-in-out;
  }
  .socials .icon-content .link:hover {
    box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
  }
  .socials .icon-content .link svg {
    width: 30px;
    height: 30px;
  }
  .socials .icon-content .link[data-social="spotify"]:hover {
    color: #1db954;
  }
  .socials .icon-content .link[data-social="discord"]:hover {
    color: #7299ee;
  }
  .socials .icon-content .link[data-social="steam"]:hover {
    color: #2a475e;
  }
}
    }
