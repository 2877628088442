@media (min-width: 1000px) {

    .maintenance {
        background-color: rgba(0, 0, 0, 0.397);
        margin-top: 10%;
        font-size: 50px;
        text-align: center;
        color:red;
    }


}




@media (max-width: 1000px) {
    .maintenance {
        background-color: rgba(0, 0, 0, 0.397);
        margin-top: 10%;
        font-size: 50px;
        text-align: center;
        color:red;
    }

}
