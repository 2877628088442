@keyframes cursor-blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  

  
  .four-oh-four {
    
    text-align: block;
    position: relative;
    top: 0;
    left: 0;
    min-width: 100vw;
    z-index: 2;
    transition: opacity 300ms ease-out;

  }
  
  .four-oh-four .dJAX_internal {
    opacity: 0.0;
  }
  
  .four-oh-four form,
  .four-oh-four input {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: black;
  }
  
  .terminal {

    position: relative;
    padding: 3rem;
    background-color: rgba(255, 255, 255, 0.171);
    border: solid 1px white;
  }
  
  .terminal .prompt {
    color: #1ff042;
    display: block;
    font-family: 'AndaleMono', monospace;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9em;
    letter-spacing: 0.15em;
    white-space: pre-wrap;
    text-shadow: 0 0 2px rgba(5, 92, 19, 0.75);
    line-height: 1;
    margin-bottom: 0.75em;
  }
  
  .terminal .prompt:before {
    content: '> ';
    display: inline-block;
  }
  
  .terminal .new-output {
    display: inline-block;
  }
  
  .terminal .new-output:after {
    display: inline-block;
    vertical-align: -0.15em;
    width: 0.75em;
    height: 1em;
    margin-left: 5px;
    background: #1ff042;
    box-shadow: 1px 1px 1px rgba(31, 240, 66, 0.65), -1px -1px 1px rgba(31, 240, 66, 0.65), 1px -1px 1px rgba(31, 240, 66, 0.65), -1px 1px 1px rgba(31, 240, 66, 0.65);
    animation: cursor-blink 1.25s steps(1) infinite;
    content: '';
  }
  
  .kittens p {
    letter-spacing: 0;
    opacity: 0;
    line-height: 1rem;
  }
  
  .kitten-gif {
    margin: 20px;
    max-width: 300px;
  }
  
  .four-oh-four-form {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
  }
  .barAbove {
    width: 100%;
    height: 30px;
    background-color: rgba(211, 211, 211, 0.705);
    border: solid 1px white;
  }
  .wholeTerminal {
    width: 95vw;
    margin: 2% 50px 0 50px;
  }
  .fakeButtons {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #000;
    position: relative;
    top: 3px;
    left: 6px;
    background-color: #ff3b47;
    border-color: #9d252b;
    display: inline-block;
  }
.fakeButtons:hover {
  transform: translateY(-3px) scale(1.05);
}
  .fakeMinimize {
    left: 11px;
    background-color: #ffc100;
    border-color: #9d802c;
  }
  
  .fakeZoom {
    left: 16px;
    background-color: #00d742;
    border-color: #049931;
  }
  
  .fakeMenu {
    width: 550px;
    box-sizing: border-box;
    height: 25px;
    background-color: #bbb;
    margin: 0 auto;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }  
