 
 
 /* YEAH I KNOW I MAKE SHIT COMMENTS
    BUT THE CODE ISNT THAT DIFFICULT <3
 */


* {
  box-sizing: border-box;
}

/* start of the mainly Desktop version */
@media (min-width: 1000px) {


/* standard things. */
body {
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'Minecraft', sans-serif;
  z-index: 1;
  overflow: hidden;
  justify-content: center;
  align-items: center;     
  background: linear-gradient(120deg, rgb(34, 34, 34), rgb(17, 17, 17));
}
  

  /* Text */
  .websiteName {
    color: rgb(162, 242, 252);
    text-align: center;
    font-size: 60px;
    margin-top: 2%;
    font-weight: bolder;
  }  
  .welcome {
    color: rgb(212, 212, 212);
    text-align: center;
    margin-top: 0.5%;
    font-size: 40px;
  }


  /* Loading page */
  
.container {
  position: relative;
  z-index: -4;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
  .cat {
    position: relative;
    top: 25vh;
    animation: rotateCat 0.8s linear infinite; 
  }
  .face{
    background-color: #242424;
    height: 100px;
    width: 150px;
    border-radius: 30px;
  }
  .ear-1,.ear-r{
    width: 0;
    height: 0;
    border-bottom: 50px solid #33292b;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    position: relative;
    z-index: -1;
  }
  .ear-1{
    bottom: 35px;
    transform: rotate(-30deg);
  }
  .ear-r{
    bottom: 85px;
    left: 110px;
    transform: rotate(30deg);
  }
  .inner-1{
    width: 0;
    height: 0;
    border-bottom: 50px solid #d36149;
    border-left: 25px solid transparent;
    right: 25px;
    position: relative;  
  }
  .inner-2{
    width: 0;
    height: 0;
    border-bottom: 50px solid #d36149;
    border-right: 25px solid transparent;
    position: relative;
  }
  .eye-1,.eye-r{
    background-color: #d2873b;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: relative; 
  }
  .eye-1{
    bottom: 75px;
    left: 30px;
  }
  .eye-r{
    bottom: 105px;
    left: 90px;
  }
  .eyeball{
    background-color: #262626;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: relative;
    top: 3px;
    left: 3px;
  }
  .nose{
    height: 0;
    width: 0;
    border-top: 8px solid #d36149;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: relative;
    bottom: 110px;
    left: 68px;  
  }
  .l1,.l2{
    background-color: #d36149;
    height: 8px;
    width: 1px;
    position: relative;
  }
  .l1{
    transform: rotate(45deg);
    right: 4px;
    bottom: 1.5px;  
  }
  .l2{
    transform: rotate(-45deg);
    bottom: 10px;
    left: 3px;  
  }
  .body{
    background-color: #242424;
    height: 120px;
    width: 150px;
    position: relative;
    left: 30px;
    border: 5px;
    border-radius: 0 65px 0 0;
  }
  .paw-1,.paw-2{
    background-color: #242424;
    height: 10px;
    width: 22px;
    position: relative;
    border-radius: 0 0 10px 10px;
  }
  .paw-1{
    top: 120px;
  }
  .paw-2{
    top: 110px;
    left: 50px;
  }
  .tail{
    background-color: #242424;
    width: 100px;
    height: 20px;
    position: relative;
    top: 80px;
    left: 100px;
    border-radius: 0 20px 20px 0;
  }

  /* loading Page animation and also the flying cats in the background*/
  @keyframes rotateCat {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }


  /*Background Stars */
.stars {
  z-index: -2;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 1px;
  width: 1px;
  border-radius: 50%;
  animation: moveStars 1.5s linear infinite;
  box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff, -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 
              29vw 19vh 0px 1px #fff, 4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff, 
              -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff, 4vw 22vh 1px 1px #fff, 
              36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff, -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 
              5vw 26vh 0px 0px #fff, -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff, 
              48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff, 43vw -27vh 1px 1px #fff, 
              20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff, 39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, 
              -40vw 45vh 0px 1px #fff, 11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff, 
              -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff, 42vw 24vh 0px 0px #fff, 
              45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff, -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 
              40vw 24vh 0px 0px #fff, 18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff, 
              27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff, -43vw 13vh 1px 0px #fff, 
              7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff, -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 
              38vw 25vh 1px 1px #fff, -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff, 
              48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff, 21vw 21vh 1px 1px #fff, 
              -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff, -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, 
              -48vw 25vh 0px 1px #fff, -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff, 
              3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff, 35vw -36vh 1px 1px #fff, 
              16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff, 3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, 
              -6vw 21vh 1px 0px #fff, 48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff, 
              -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff, 29vw -37vh 1px 1px #fff, 
              28vw 2vh 0px 0px #fff, -22vw 14vh 1px 0px #fff, 15vw -18vh 0px 1px #fff, -40vw -28vh 0px 0px #fff, -32vw -35vh 0px 1px #fff, 
              27vw 22vh 0px 1px #fff, 9vw -5vh 0px 1px #fff, -2vw 18vh 1px 1px #fff, -30vw 20vh 1px 1px #fff, 
              -42vw -5vh 1px 1px #fff, -25vw -37vh 0px 1px #fff, -38vw -21vh 0px 1px #fff, 7vw 25vh 1px 1px #fff, 
              33vw 19vh 0px 0px #fff, -19vw 30vh 1px 1px #fff, -14vw 3vh 1px 0px #fff, -14vw -10vh 0px 0px #fff, 
              3vw 20vh 0px 0px #fff, -31vw 38vh 0px 0px #fff, 3vw 40vh 1px 1px #fff, 14vw -17vh 1px 1px #fff, 
              45vw -10vh 1px 0px #fff, 24vw -20vh 0px 0px #fff, 42vw 45vh 0px 0px #fff, 39vw -30vh 1px 1px #fff, 
              18vw -3vh 0px 0px #fff, 11vw 20vh 0px 1px #fff, 36vw 45vh 1px 1px #fff, -18vw -8vh 0px 1px #fff, 
              -37vw 42vh 0px 1px #fff, 15vw -28vh 1px 0px #fff, 22vw 27vh 1px 0px #fff, 42vw -25vh 0px 1px #fff, 
              -3vw 15vh 0px 0px #fff, -25vw -40vh 1px 0px #fff, -4vw -20vh 1px 1px #fff, 39vw 20vh 0px 0px #fff, 
              42vw -43vh 1px 0px #fff, -34vw -15vh 0px 0px #fff, -41vw 10vh 0px 1px #fff, 33vw 13vh 0px 1px #fff, 
              38vw 20vh 0px 0px #fff, 20vw 8vh 0px 0px #fff, -13vw -18vh 1px 0px #fff, -21vw 45vh 0px 1px #fff, 
              25vw -40vh 1px 0px #fff, -4vw -20vh 0px 1px #fff, -12vw -22vh 0px 0px #fff, -38vw 10vh 1px 0px #fff, 
              10vw 23vh 1px 0px #fff, 43vw 7vh 0px 0px #fff, -41vw 13vh 1px 1px #fff, -2vw -25vh 0px 1px #fff, 
              35vw 23vh 1px 1px #fff, -36vw -4vh 1px 0px #fff, 8vw -2vh 1px 0px #fff, 45vw 16vh 0px 0px #fff, 
              45vw 12vh 0px 1px #fff, 16vw -25vh 0px 0px #fff, 6vw 10vh 0px 0px #fff, 23vw 18vh 1px 1px #fff, 
              -13vw -12vh 0px 1px #fff, -8vw -35vh 1px 0px #fff, -13vw 45vh 1px 1px #fff, -20vw 22vh 1px 1px #fff, 
              -45vw 22vh 0px 1px #fff, -28vw -16vh 0px 1px #fff, 7vw 34vh 1px 1px #fff, -40vw 25vh 0px 0px #fff, 
              6vw -22vh 0px 1px #fff, -36vw 11vh 0px 1px #fff, -39vw 28vh 0px 1px #fff, 32vw -33vh 1px 1px #fff, 
              19vw 46vh 0px 0px #fff, 5vw 36vh 0px 0px #fff, 4vw -30vh 0px 1px #fff, -41vw -3vh 1px 0px #fff, 
              -5vw 18vh 1px 0px #fff, 45vw 8vh 1px 1px #fff, -39vw 28vh 1px 1px #fff, 26vw -10vh 1px 1px #fff, 
              -45vw 11vh 1px 0px #fff, -39vw 28vh 1px 1px #fff, 31vw 12vh 1px 1px #fff, 27vw -33vh 1px 1px #fff, 
              25vw 4vh 0px 0px #fff;;
}
.stars2 {
  z-index: -2;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 1px;
  width: 1px;
  animation: moveStars 1s linear infinite;
  animation-delay: 0.5s;
  box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff, -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 
              29vw 19vh 0px 1px #fff, 4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff, 
              -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff, 4vw 22vh 1px 1px #fff, 
              36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff, -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 
              5vw 26vh 0px 0px #fff, -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff, 
              48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff, 43vw -27vh 1px 1px #fff, 
              20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff, 39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, 
              -40vw 45vh 0px 1px #fff, 11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff, 
              -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff, 42vw 24vh 0px 0px #fff, 
              45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff, -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 
              40vw 24vh 0px 0px #fff, 18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff, 
              27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff, -43vw 13vh 1px 0px #fff, 
              7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff, -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 
              38vw 25vh 1px 1px #fff, -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff, 
              48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff, 21vw 21vh 1px 1px #fff, 
              -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff, -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, 
              -48vw 25vh 0px 1px #fff, -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff, 
              3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff, 35vw -36vh 1px 1px #fff, 
              16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff, 3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, 
              -6vw 21vh 1px 0px #fff, 48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff, 
              -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff, 29vw -37vh 1px 1px #fff, 
              28vw 2vh 0px 0px #fff, -22vw 14vh 1px 0px #fff, 15vw -18vh 0px 1px #fff, -40vw -28vh 0px 0px #fff, -32vw -35vh 0px 1px #fff, 
              27vw 22vh 0px 1px #fff, 9vw -5vh 0px 1px #fff, -2vw 18vh 1px 1px #fff, -30vw 20vh 1px 1px #fff, 
              -42vw -5vh 1px 1px #fff, -25vw -37vh 0px 1px #fff, -38vw -21vh 0px 1px #fff, 7vw 25vh 1px 1px #fff, 
              33vw 19vh 0px 0px #fff, -19vw 30vh 1px 1px #fff, -14vw 3vh 1px 0px #fff, -14vw -10vh 0px 0px #fff, 
              3vw 20vh 0px 0px #fff, -31vw 38vh 0px 0px #fff, 3vw 40vh 1px 1px #fff, 14vw -17vh 1px 1px #fff, 
              45vw -10vh 1px 0px #fff, 24vw -20vh 0px 0px #fff, 42vw 45vh 0px 0px #fff, 39vw -30vh 1px 1px #fff, 
              18vw -3vh 0px 0px #fff, 11vw 20vh 0px 1px #fff, 36vw 45vh 1px 1px #fff, -18vw -8vh 0px 1px #fff, 
              -37vw 42vh 0px 1px #fff, 15vw -28vh 1px 0px #fff, 22vw 27vh 1px 0px #fff, 42vw -25vh 0px 1px #fff, 
              -3vw 15vh 0px 0px #fff, -25vw -40vh 1px 0px #fff, -4vw -20vh 1px 1px #fff, 39vw 20vh 0px 0px #fff, 
              42vw -43vh 1px 0px #fff, -34vw -15vh 0px 0px #fff, -41vw 10vh 0px 1px #fff, 33vw 13vh 0px 1px #fff, 
              38vw 20vh 0px 0px #fff, 20vw 8vh 0px 0px #fff, -13vw -18vh 1px 0px #fff, -21vw 45vh 0px 1px #fff, 
              25vw -40vh 1px 0px #fff, -4vw -20vh 0px 1px #fff, -12vw -22vh 0px 0px #fff, -38vw 10vh 1px 0px #fff, 
              10vw 23vh 1px 0px #fff, 43vw 7vh 0px 0px #fff, -41vw 13vh 1px 1px #fff, -2vw -25vh 0px 1px #fff, 
              35vw 23vh 1px 1px #fff, -36vw -4vh 1px 0px #fff, 8vw -2vh 1px 0px #fff, 45vw 16vh 0px 0px #fff, 
              45vw 12vh 0px 1px #fff, 16vw -25vh 0px 0px #fff, 6vw 10vh 0px 0px #fff, 23vw 18vh 1px 1px #fff, 
              -13vw -12vh 0px 1px #fff, -8vw -35vh 1px 0px #fff, -13vw 45vh 1px 1px #fff, -20vw 22vh 1px 1px #fff, 
              -45vw 22vh 0px 1px #fff, -28vw -16vh 0px 1px #fff, 7vw 34vh 1px 1px #fff, -40vw 25vh 0px 0px #fff, 
              6vw -22vh 0px 1px #fff, -36vw 11vh 0px 1px #fff, -39vw 28vh 0px 1px #fff, 32vw -33vh 1px 1px #fff, 
              19vw 46vh 0px 0px #fff, 5vw 36vh 0px 0px #fff, 4vw -30vh 0px 1px #fff, -41vw -3vh 1px 0px #fff, 
              -5vw 18vh 1px 0px #fff, 45vw 8vh 1px 1px #fff, -39vw 28vh 1px 1px #fff, 26vw -10vh 1px 1px #fff, 
              -45vw 11vh 1px 0px #fff, -39vw 28vh 1px 1px #fff, 31vw 12vh 1px 1px #fff, 27vw -33vh 1px 1px #fff, 
              25vw 4vh 0px 0px #fff;;
}
.stars3 {
  z-index: -2;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 1px;
  width: 1px;
  animation: moveStars 2s linear infinite;
  animation-delay: 1s;
  box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff, -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 
              29vw 19vh 0px 1px #fff, 4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff, 
              -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff, 4vw 22vh 1px 1px #fff, 
              36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff, -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 
              5vw 26vh 0px 0px #fff, -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff, 
              48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff, 43vw -27vh 1px 1px #fff, 
              20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff, 39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, 
              -40vw 45vh 0px 1px #fff, 11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff, 
              -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff, 42vw 24vh 0px 0px #fff, 
              45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff, -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 
              40vw 24vh 0px 0px #fff, 18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff, 
              27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff, -43vw 13vh 1px 0px #fff, 
              7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff, -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 
              38vw 25vh 1px 1px #fff, -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff, 
              48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff, 21vw 21vh 1px 1px #fff, 
              -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff, -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, 
              -48vw 25vh 0px 1px #fff, -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff, 
              3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff, 35vw -36vh 1px 1px #fff, 
              16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff, 3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, 
              -6vw 21vh 1px 0px #fff, 48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff, 
              -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff, 29vw -37vh 1px 1px #fff, 
              28vw 2vh 0px 0px #fff, -22vw 14vh 1px 0px #fff, 15vw -18vh 0px 1px #fff, -40vw -28vh 0px 0px #fff, -32vw -35vh 0px 1px #fff, 
              27vw 22vh 0px 1px #fff, 9vw -5vh 0px 1px #fff, -2vw 18vh 1px 1px #fff, -30vw 20vh 1px 1px #fff, 
              -42vw -5vh 1px 1px #fff, -25vw -37vh 0px 1px #fff, -38vw -21vh 0px 1px #fff, 7vw 25vh 1px 1px #fff, 
              33vw 19vh 0px 0px #fff, -19vw 30vh 1px 1px #fff, -14vw 3vh 1px 0px #fff, -14vw -10vh 0px 0px #fff, 
              3vw 20vh 0px 0px #fff, -31vw 38vh 0px 0px #fff, 3vw 40vh 1px 1px #fff, 14vw -17vh 1px 1px #fff, 
              45vw -10vh 1px 0px #fff, 24vw -20vh 0px 0px #fff, 42vw 45vh 0px 0px #fff, 39vw -30vh 1px 1px #fff, 
              18vw -3vh 0px 0px #fff, 11vw 20vh 0px 1px #fff, 36vw 45vh 1px 1px #fff, -18vw -8vh 0px 1px #fff, 
              -37vw 42vh 0px 1px #fff, 15vw -28vh 1px 0px #fff, 22vw 27vh 1px 0px #fff, 42vw -25vh 0px 1px #fff, 
              -3vw 15vh 0px 0px #fff, -25vw -40vh 1px 0px #fff, -4vw -20vh 1px 1px #fff, 39vw 20vh 0px 0px #fff, 
              42vw -43vh 1px 0px #fff, -34vw -15vh 0px 0px #fff, -41vw 10vh 0px 1px #fff, 33vw 13vh 0px 1px #fff, 
              38vw 20vh 0px 0px #fff, 20vw 8vh 0px 0px #fff, -13vw -18vh 1px 0px #fff, -21vw 45vh 0px 1px #fff, 
              25vw -40vh 1px 0px #fff, -4vw -20vh 0px 1px #fff, -12vw -22vh 0px 0px #fff, -38vw 10vh 1px 0px #fff, 
              10vw 23vh 1px 0px #fff, 43vw 7vh 0px 0px #fff, -41vw 13vh 1px 1px #fff, -2vw -25vh 0px 1px #fff, 
              35vw 23vh 1px 1px #fff, -36vw -4vh 1px 0px #fff, 8vw -2vh 1px 0px #fff, 45vw 16vh 0px 0px #fff, 
              45vw 12vh 0px 1px #fff, 16vw -25vh 0px 0px #fff, 6vw 10vh 0px 0px #fff, 23vw 18vh 1px 1px #fff, 
              -13vw -12vh 0px 1px #fff, -8vw -35vh 1px 0px #fff, -13vw 45vh 1px 1px #fff, -20vw 22vh 1px 1px #fff, 
              -45vw 22vh 0px 1px #fff, -28vw -16vh 0px 1px #fff, 7vw 34vh 1px 1px #fff, -40vw 25vh 0px 0px #fff, 
              6vw -22vh 0px 1px #fff, -36vw 11vh 0px 1px #fff, -39vw 28vh 0px 1px #fff, 32vw -33vh 1px 1px #fff, 
              19vw 46vh 0px 0px #fff, 5vw 36vh 0px 0px #fff, 4vw -30vh 0px 1px #fff, -41vw -3vh 1px 0px #fff, 
              -5vw 18vh 1px 0px #fff, 45vw 8vh 1px 1px #fff, -39vw 28vh 1px 1px #fff, 26vw -10vh 1px 1px #fff, 
              -45vw 11vh 1px 0px #fff, -39vw 28vh 1px 1px #fff, 31vw 12vh 1px 1px #fff, 27vw -33vh 1px 1px #fff, 
              25vw 4vh 0px 0px #fff;;
}
.stars4 {
  z-index: -5;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 1px;
  width: 1px;
  animation: moveStars 1.5s linear infinite;
  animation-delay: 1.5s;
  box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff, -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 
              29vw 19vh 0px 1px #fff, 4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff, 
              -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff, 4vw 22vh 1px 1px #fff, 
              36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff, -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 
              5vw 26vh 0px 0px #fff, -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff, 
              48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff, 43vw -27vh 1px 1px #fff, 
              20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff, 39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, 
              -40vw 45vh 0px 1px #fff, 11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff, 
              -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff, 42vw 24vh 0px 0px #fff, 
              45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff, -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 
              40vw 24vh 0px 0px #fff, 18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff, 
              27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff, -43vw 13vh 1px 0px #fff, 
              7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff, -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 
              38vw 25vh 1px 1px #fff, -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff, 
              48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff, 21vw 21vh 1px 1px #fff, 
              -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff, -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, 
              -48vw 25vh 0px 1px #fff, -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff, 
              3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff, 35vw -36vh 1px 1px #fff, 
              16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff, 3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, 
              -6vw 21vh 1px 0px #fff, 48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff, 
              -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff, 29vw -37vh 1px 1px #fff, 
              28vw 2vh 0px 0px #fff, -22vw 14vh 1px 0px #fff, 15vw -18vh 0px 1px #fff, -40vw -28vh 0px 0px #fff, -32vw -35vh 0px 1px #fff, 
              27vw 22vh 0px 1px #fff, 9vw -5vh 0px 1px #fff, -2vw 18vh 1px 1px #fff, -30vw 20vh 1px 1px #fff, 
              -42vw -5vh 1px 1px #fff, -25vw -37vh 0px 1px #fff, -38vw -21vh 0px 1px #fff, 7vw 25vh 1px 1px #fff, 
              33vw 19vh 0px 0px #fff, -19vw 30vh 1px 1px #fff, -14vw 3vh 1px 0px #fff, -14vw -10vh 0px 0px #fff, 
              3vw 20vh 0px 0px #fff, -31vw 38vh 0px 0px #fff, 3vw 40vh 1px 1px #fff, 14vw -17vh 1px 1px #fff, 
              45vw -10vh 1px 0px #fff, 24vw -20vh 0px 0px #fff, 42vw 45vh 0px 0px #fff, 39vw -30vh 1px 1px #fff, 
              18vw -3vh 0px 0px #fff, 11vw 20vh 0px 1px #fff, 36vw 45vh 1px 1px #fff, -18vw -8vh 0px 1px #fff, 
              -37vw 42vh 0px 1px #fff, 15vw -28vh 1px 0px #fff, 22vw 27vh 1px 0px #fff, 42vw -25vh 0px 1px #fff, 
              -3vw 15vh 0px 0px #fff, -25vw -40vh 1px 0px #fff, -4vw -20vh 1px 1px #fff, 39vw 20vh 0px 0px #fff, 
              42vw -43vh 1px 0px #fff, -34vw -15vh 0px 0px #fff, -41vw 10vh 0px 1px #fff, 33vw 13vh 0px 1px #fff, 
              38vw 20vh 0px 0px #fff, 20vw 8vh 0px 0px #fff, -13vw -18vh 1px 0px #fff, -21vw 45vh 0px 1px #fff, 
              25vw -40vh 1px 0px #fff, -4vw -20vh 0px 1px #fff, -12vw -22vh 0px 0px #fff, -38vw 10vh 1px 0px #fff, 
              10vw 23vh 1px 0px #fff, 43vw 7vh 0px 0px #fff, -41vw 13vh 1px 1px #fff, -2vw -25vh 0px 1px #fff, 
              35vw 23vh 1px 1px #fff, -36vw -4vh 1px 0px #fff, 8vw -2vh 1px 0px #fff, 45vw 16vh 0px 0px #fff, 
              45vw 12vh 0px 1px #fff, 16vw -25vh 0px 0px #fff, 6vw 10vh 0px 0px #fff, 23vw 18vh 1px 1px #fff, 
              -13vw -12vh 0px 1px #fff, -8vw -35vh 1px 0px #fff, -13vw 45vh 1px 1px #fff, -20vw 22vh 1px 1px #fff, 
              -45vw 22vh 0px 1px #fff, -28vw -16vh 0px 1px #fff, 7vw 34vh 1px 1px #fff, -40vw 25vh 0px 0px #fff, 
              6vw -22vh 0px 1px #fff, -36vw 11vh 0px 1px #fff, -39vw 28vh 0px 1px #fff, 32vw -33vh 1px 1px #fff, 
              19vw 46vh 0px 0px #fff, 5vw 36vh 0px 0px #fff, 4vw -30vh 0px 1px #fff, -41vw -3vh 1px 0px #fff, 
              -5vw 18vh 1px 0px #fff, 45vw 8vh 1px 1px #fff, -39vw 28vh 1px 1px #fff, 26vw -10vh 1px 1px #fff, 
              -45vw 11vh 1px 0px #fff, -39vw 28vh 1px 1px #fff, 31vw 12vh 1px 1px #fff, 27vw -33vh 1px 1px #fff, 
              25vw 4vh 0px 0px #fff;;
}
.stars5 {
  z-index: -5;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 1px;
  width: 1px;
  box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff, -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 
              29vw 19vh 0px 1px #fff, 4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff, 
              -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff, 4vw 22vh 1px 1px #fff, 
              36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff, -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 
              5vw 26vh 0px 0px #fff, -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff, 
              48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff, 43vw -27vh 1px 1px #fff, 
              20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff, 39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, 
              -40vw 45vh 0px 1px #fff, 11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff, 
              -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff, 42vw 24vh 0px 0px #fff, 
              45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff, -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 
              40vw 24vh 0px 0px #fff, 18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff, 
              27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff, -43vw 13vh 1px 0px #fff, 
              7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff, -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 
              38vw 25vh 1px 1px #fff, -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff, 
              48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff, 21vw 21vh 1px 1px #fff, 
              -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff, -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, 
              -48vw 25vh 0px 1px #fff, -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff, 
              3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff, 35vw -36vh 1px 1px #fff, 
              16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff, 3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, 
              -6vw 21vh 1px 0px #fff, 48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff, 
              -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff, 29vw -37vh 1px 1px #fff, 
              28vw 2vh 0px 0px #fff, -22vw 14vh 1px 0px #fff, 15vw -18vh 0px 1px #fff, -40vw -28vh 0px 0px #fff, -32vw -35vh 0px 1px #fff, 
              27vw 22vh 0px 1px #fff, 9vw -5vh 0px 1px #fff, -2vw 18vh 1px 1px #fff, -30vw 20vh 1px 1px #fff, 
              -42vw -5vh 1px 1px #fff, -25vw -37vh 0px 1px #fff, -38vw -21vh 0px 1px #fff, 7vw 25vh 1px 1px #fff, 
              33vw 19vh 0px 0px #fff, -19vw 30vh 1px 1px #fff, -14vw 3vh 1px 0px #fff, -14vw -10vh 0px 0px #fff, 
              3vw 20vh 0px 0px #fff, -31vw 38vh 0px 0px #fff, 3vw 40vh 1px 1px #fff, 14vw -17vh 1px 1px #fff, 
              45vw -10vh 1px 0px #fff, 24vw -20vh 0px 0px #fff, 42vw 45vh 0px 0px #fff, 39vw -30vh 1px 1px #fff, 
              18vw -3vh 0px 0px #fff, 11vw 20vh 0px 1px #fff, 36vw 45vh 1px 1px #fff, -18vw -8vh 0px 1px #fff, 
              -37vw 42vh 0px 1px #fff, 15vw -28vh 1px 0px #fff, 22vw 27vh 1px 0px #fff, 42vw -25vh 0px 1px #fff, 
              -3vw 15vh 0px 0px #fff, -25vw -40vh 1px 0px #fff, -4vw -20vh 1px 1px #fff, 39vw 20vh 0px 0px #fff, 
              42vw -43vh 1px 0px #fff, -34vw -15vh 0px 0px #fff, -41vw 10vh 0px 1px #fff, 33vw 13vh 0px 1px #fff, 
              38vw 20vh 0px 0px #fff, 20vw 8vh 0px 0px #fff, -13vw -18vh 1px 0px #fff, -21vw 45vh 0px 1px #fff, 
              25vw -40vh 1px 0px #fff, -4vw -20vh 0px 1px #fff, -12vw -22vh 0px 0px #fff, -38vw 10vh 1px 0px #fff, 
              10vw 23vh 1px 0px #fff, 43vw 7vh 0px 0px #fff, -41vw 13vh 1px 1px #fff, -2vw -25vh 0px 1px #fff, 
              35vw 23vh 1px 1px #fff, -36vw -4vh 1px 0px #fff, 8vw -2vh 1px 0px #fff, 45vw 16vh 0px 0px #fff, 
              45vw 12vh 0px 1px #fff, 16vw -25vh 0px 0px #fff, 6vw 10vh 0px 0px #fff, 23vw 18vh 1px 1px #fff, 
              -13vw -12vh 0px 1px #fff, -8vw -35vh 1px 0px #fff, -13vw 45vh 1px 1px #fff, -20vw 22vh 1px 1px #fff, 
              -45vw 22vh 0px 1px #fff, -28vw -16vh 0px 1px #fff, 7vw 34vh 1px 1px #fff, -40vw 25vh 0px 0px #fff, 
              6vw -22vh 0px 1px #fff, -36vw 11vh 0px 1px #fff, -39vw 28vh 0px 1px #fff, 32vw -33vh 1px 1px #fff, 
              19vw 46vh 0px 0px #fff, 5vw 36vh 0px 0px #fff, 4vw -30vh 0px 1px #fff, -41vw -3vh 1px 0px #fff, 
              -5vw 18vh 1px 0px #fff, 45vw 8vh 1px 1px #fff, -39vw 28vh 1px 1px #fff, 26vw -10vh 1px 1px #fff, 
              -45vw 11vh 1px 0px #fff, -39vw 28vh 1px 1px #fff, 31vw 12vh 1px 1px #fff, 27vw -33vh 1px 1px #fff, 
              25vw 4vh 0px 0px #fff;;
}
  /* The Animation for the Stars to move */
@keyframes moveStars {
  from {
      transform: scale(1);
      opacity: 0;

  }
  to {
      transform: scale(5);
      opacity: 0.8;

  }
}

  /* The Flying Cats(container for them) in the Background */
.catcontainer {
  left: -15vw;
  position: absolute;
  animation: moveRight 6s infinite linear, changeTop 12s infinite linear;
  z-index: -4;
}
.catcontainer2 {
  left: -15vw;
  position: absolute;
  animation: moveRight2 9.5s infinite linear, changeTop2 19s infinite linear;
  animation-delay: 7s;
  z-index: -2;
}
.catcontainer3 {
  left: -15vw;
  position: absolute;
  animation: moveRight3 10s infinite linear, changeTop3 24s infinite linear;
  animation-delay: 20s;
  z-index: -3;
}
    /*  The to the right animation for the Flying Cats */
@keyframes moveRight {
  from {
    transform: translateX(-10vw);
  }
  to {
    transform: translateX(130vw);
  }
}
@keyframes moveRight2 {
  from {
    transform: translateX(-10vw);
  }
  to {
    transform: translateX(130vw);
  }
}
@keyframes moveRight3 {
  from {
    transform: translateX(-10vw);
  }
  to {
    transform: translateX(130vw);
  }
}
@keyframes changeTop {
  0%, 25% {
    top: 0;
  }
  50% {
    top: 25vh;
  }
  75%, 100% {
    top: 50vh;
  }
}
@keyframes changeTop2 {
  0%, 20% {
    top: 5vh;
  }
  40% {
    top: -15vh;
  }
  60% {
    top: 80vh;
  }
  80%, 100% {
    top: -20vh;
  }
}
@keyframes changeTop3 {
  0%, 16.66% {
    top: 20vh;
  }
  33.33% {
    top: -10vh;
  }
  50% {
    top: 40vh;
  }
  66.66% {
    top: 90vh;
  }
  83.33%, 100% {
    top: -40vh;
  }
}
  /* The Flying cats in the background */
.cat2 {
  z-index: -5;
  position:absolute;
  left: -10vw;
  animation: rotateCatSpace 1.5s linear infinite; 
}
.cat3 {
  z-index: -6;
  position:absolute;
  left: -10vw;
  animation: rotateCatSpace 1.5s linear infinite; 
}
.cat4 {
  z-index: -7;
  position:absolute;
  left: -10vw;
  animation: rotateCatSpace 1.5s linear infinite;
 
}

  /* The rotate Animation for the flying Cats*/
@keyframes rotateCatSpace {
  0% {
    transform: rotate(360deg) scale(0.5);
  }
  100% {
    transform: rotate(0deg) scale(0.5);  }

}

  /* The Cat Designs*/

  /* cat2 */
.face_3{
  background-color: white;
  height: 100px;
  width: 150px;
  border-radius: 30px;
}
.ear-1_3,.ear-r_3{
  width: 0;
  height: 0;
  border-bottom: 50px solid white;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  position: relative;
  z-index: -1;
}
.ear-1_3{
  bottom: 35px;
  transform: rotate(-30deg);
}
.ear-r_3{
  bottom: 85px;
  left: 110px;
  transform: rotate(30deg);
}
.inner-1_3{
  width: 0;
  height: 0;
  border-bottom: 50px solid #d36149;
  border-left: 25px solid transparent;
  right: 25px;
  position: relative;  
}
.inner-2_3{
  width: 0;
  height: 0;
  border-bottom: 50px solid #d36149;
  border-right: 25px solid transparent;
  position: relative;
}
.eye-1_3,.eye-r_3{
  background-color: gray;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: relative; 
}
.eye-1_3{
  bottom: 75px;
  left: 30px;
}
.eye-r_3{
  bottom: 105px;
  left: 90px;
}
.eyeball_3{
  background-color: lightcyan;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: relative;
  top: 3px;
  left: 3px;
}
.nose_3{
  height: 0;
  width: 0;
  border-top: 8px solid #d36149;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  position: relative;
  bottom: 110px;
  left: 68px;  
}
.l1_3,.l2_3{
  background-color: #d36149;
  height: 8px;
  width: 1px;
  position: relative;
}
.l1_3{
  transform: rotate(45deg);
  right: 4px;
  bottom: 1.5px;  
}
.l2_3{
  transform: rotate(-45deg);
  bottom: 10px;
  left: 3px;  
}
.body_3{
  background-color: white;
  height: 120px;
  width: 150px;
  position: relative;
  left: 30px;
  border: 5px;
  border-radius: 0 65px 0 0;
}
.paw-1_3,.paw-2_3{
  background-color: white;
  height: 10px;
  width: 22px;
  position: relative;
  border-radius: 0 0 10px 10px;
}
.paw-1_3{
  top: 120px;
}
.paw-2_3{
  top: 110px;
  left: 50px;
}
.tail_3{
  background-color: white;
  width: 100px;
  height: 20px;
  position: relative;
  top: 80px;
  left: 100px;
  border-radius: 0 20px 20px 0;
}

 /* cat3 */
.face_4{
  background-color: burlywood;
  height: 100px;
  width: 150px;
  border-radius: 30px;
}
.ear-1_4,.ear-r_4{
  width: 0;
  height: 0;
  border-bottom: 50px solid burlywood;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  position: relative;
  z-index: -1;
}
.ear-1_4{
  bottom: 35px;
  transform: rotate(-30deg);
}
.ear-r_4{
  bottom: 85px;
  left: 110px;
  transform: rotate(30deg);
}
.inner-1_4{
  width: 0;
  height: 0;
  border-bottom: 50px solid #d36149;
  border-left: 25px solid transparent;
  right: 25px;
  position: relative;  
}
.inner-2_4{
  width: 0;
  height: 0;
  border-bottom: 50px solid #d36149;
  border-right: 25px solid transparent;
  position: relative;
}
.eye-1_4,.eye-r_4{
  background-color: lightgreen;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: relative; 
}
.eye-1_4{
  bottom: 75px;
  left: 30px;
}
.eye-r_4{
  bottom: 105px;
  left: 90px;
}
.eyeball_4{
  background-color: black;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: relative;
  top: 3px;
  left: 3px;
}
.nose_4{
  height: 0;
  width: 0;
  border-top: 8px solid #d36149;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  position: relative;
  bottom: 110px;
  left: 68px;  
}
.l1_4,.l2_4{
  background-color: burlywood;
  height: 8px;
  width: 1px;
  position: relative;
}
.l1_4{
  transform: rotate(45deg);
  right: 4px;
  bottom: 1.5px;  
}
.l2_4{
  transform: rotate(-45deg);
  bottom: 10px;
  left: 3px;  
}
.body_4{
  background-color: burlywood;
  height: 120px;
  width: 150px;
  position: relative;
  left: 30px;
  border: 5px;
  border-radius: 0 65px 0 0;
}
.paw-1_4,.paw-2_4{
  background-color: burlywood;
  height: 10px;
  width: 22px;
  position: relative;
  border-radius: 0 0 10px 10px;
}
.paw-1_4{
  top: 120px;
}
.paw-2_4{
  top: 110px;
  left: 50px;
}
.tail_4{
  background-color: burlywood;
  width: 100px;
  height: 20px;
  position: relative;
  top: 80px;
  left: 100px;
  border-radius: 0 20px 20px 0;
}


  /* Here are the Selections - no explanation needed*/
.selections {
  z-index: -3;
  display: flex;
  align-items: center;
  width: 80vw;
  transition: all ease-in-out 0.3s;
  text-align: center;
  margin: 0 auto; /* Center the container */
  justify-content: center; /* Align items horizontally */
  flex-wrap: wrap; /* Allow wrapping if needed */
}
.selection {
  margin-top: 3%;
  margin-right: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 15%;
  padding-left: 1%;
  padding-right: 1%;
  text-align: center;
  height: 40vh;
  font-size: 25px;
  color: rgb(212, 212, 212);
  background: rgba(24, 24, 24, 0.534);
  background: linear-gradient(to right, #1111118e, #3838389a 33%, #383838a4 66%, #111111a1);
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.151);
  background-size: cover;
  background-position: center;
  border: solid 2px rgba(0, 0, 0, 0.37);
  filter: grayscale(0);
}
.selection:hover {
  color: aqua;
  cursor: pointer;
  transform: scale(1.01);
  transform: translateY(-10px);
}
.selection:nth-child(1) {
  background-image: url('../pics/pussys.jpg');
}

.selection:nth-child(2) {
  background-image: url('../pics/pic.jpg');
}

.selection:nth-child(3) {
  background-image: url('../pics/gamingcat.jpg');
}


.animated-button {
  left: 1.5vw;
  top: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 5% 20%;
  border: 0px solid;
  border-color: transparent;
  font-size: 18px;
  background-color: rgba(26, 26, 26, 0.849);
  border-radius: 100px;
  color: rgb(162, 242, 252);
  box-shadow: 0 0 0 2px rgb(162, 242, 252);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  font-family: 'Minecraft', sans-serif;

}

.animated-button svg {
  position: absolute;
  width: 24px;
  fill: rgb(162, 242, 252);
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .arr-2 {
  left: -25%;
}

.animated-button .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: rgb(162, 242, 252);
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .text {
  font-family: 'Minecraft', sans-serif;
  position: relative;
  z-index: 1;
  transform: translateX(0px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.selection:hover .animated-button {
  box-shadow: 0 0 0 12px transparent;
  color: #212121;
  border-radius: 12px;
}


.selection:hover .animated-button .arr-2 {
  left: 16px;
}

.selection:hover .animated-button .text {
  transform: translateX(12px);
}

.selection:hover .animated-button svg {
  fill: #212121;
}

.animated-button:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px rgb(162, 242, 252);
}

.selection:hover .animated-button .circle {
  width: 220px;
  height: 220px;
  opacity: 1;
}



  /* The tooltip E-mail also no explanation needed*/
.tooltip-container {
  top: 20vh; 
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90px;
  width: 120px;
  border-radius: 5px;
  background-color: rgb(35, 35, 35);
  background-image: linear-gradient(
    to left bottom,
    rgb(35, 35, 35),
    rgb(41, 41, 41),
    rgb(35, 35, 35),
    rgb(41, 41, 41),
    rgb(35, 35, 35)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.151);
  position: relative;
  transition: transform 0.3s ease;
}
.tooltip-container::before {
  
  position: absolute;
  content: "";
  top: -50%;
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
  border-radius: 5px;
  background-color: rgb(35, 35, 35);
  background-image: linear-gradient(
    to left bottom,
    rgb(35, 35, 35),
    rgb(41, 41, 41),
    rgb(35, 35, 35),
    rgb(41, 41, 41),
    rgb(35, 35, 35)
  );
  width: 100%;
  height: 50%;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateX(-150deg) translateY(-110%);
  transition: transform 0.3s ease;
}

.tooltip-container .text {
  
  color: rgb(65, 65, 65);
  font-weight: bold;
  font-size: 40px;
}

.tooltip {
  position: absolute;
  top: -20px;
  opacity: 0;
  color: rgb(177, 177, 177);
  background-size: 4px 4px, 4px 4px, 80px 80px, 80px 80px, 80px 80px, 80px 80px,
    80px 80px, 80px 80px;
  padding: 5px 10px;
  height: 70px;
  width: 110px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  pointer-events: none;
  letter-spacing: 0.5px;
  font-size: 18px;
  font-weight: 600;
  text-shadow: 10px salmon;
}
.tooltip-container:hover {
  border-top-left-radius: 0;
  border-top-right-radius: 0;

}

.tooltip-container:hover::before {
  transform: rotateY(0);
  background-image: none;
  background-color: rgb(35, 35, 35);

}

.tooltip-container:hover .tooltip {
  top: -80px;
  opacity: 1;
  transition-duration: 0.3s;
  
}

.hi {
    
color: rgb(65, 65, 65);
font-weight: bold;
margin-right: 10%;
}
.tooltip-container:hover .hi {
color: aqua;
font-weight: bold;
margin-right: 10%;
}



}

















 /* The small screen and phone Version*/
@media (max-width: 1000px) {


 /* Basic Body again */
body {
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'Minecraft', sans-serif;
  z-index: 1;
  overflow: hidden;
  justify-content: center;
  align-items: center;     
  background: linear-gradient(120deg, rgb(34, 34, 34), rgb(17, 17, 17));
}

   /* Text... */
  .websiteName {
    color: rgb(162, 242, 252);
    text-align: center;
    font-size: 40px;
    margin-top: 6%;
    font-weight: bolder;
  }
  .welcome {
    color: rgb(212, 212, 212);
    text-align: center;
    margin-top: 0.5%;
    font-size: 30px;
  }

   /* Loading screen */
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
   /* cat1 and cat design*/
    .cat {
      position: relative;
      top: 25vh;
      animation: rotateSmallerCat 0.8s linear infinite; 
    }
    .face{
      background-color: #242424;
      height: 100px;
      width: 150px;
      border-radius: 30px;
    }
    .ear-1,.ear-r{
      width: 0;
      height: 0;
      border-bottom: 50px solid #33292b;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      position: relative;
      z-index: -1;
    }
    .ear-1{
      bottom: 35px;
      transform: rotate(-30deg);
    }
    .ear-r{
      bottom: 85px;
      left: 110px;
      transform: rotate(30deg);
    }
    .inner-1{
      width: 0;
      height: 0;
      border-bottom: 50px solid #d36149;
      border-left: 25px solid transparent;
      right: 25px;
      position: relative;  
    }
    .inner-2{
      width: 0;
      height: 0;
      border-bottom: 50px solid #d36149;
      border-right: 25px solid transparent;
      position: relative;
    }
    .eye-1,.eye-r{
      background-color: #d2873b;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      position: relative; 
    }
    .eye-1{
      bottom: 75px;
      left: 30px;
    }
    .eye-r{
      bottom: 105px;
      left: 90px;
    }
    .eyeball{
      background-color: #262626;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      position: relative;
      top: 3px;
      left: 3px;
    }
    .nose{
      height: 0;
      width: 0;
      border-top: 8px solid #d36149;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      position: relative;
      bottom: 110px;
      left: 68px;  
    }
    .l1,.l2{
      background-color: #d36149;
      height: 8px;
      width: 1px;
      position: relative;
    }
    .l1{
      transform: rotate(45deg);
      right: 4px;
      bottom: 1.5px;  
    }
    .l2{
      transform: rotate(-45deg);
      bottom: 10px;
      left: 3px;  
    }
    .body{
      background-color: #242424;
      height: 120px;
      width: 150px;
      position: relative;
      left: 30px;
      border: 5px;
      border-radius: 0 65px 0 0;
    }
    .paw-1,.paw-2{
      background-color: #242424;
      height: 10px;
      width: 22px;
      position: relative;
      border-radius: 0 0 10px 10px;
    }
    .paw-1{
      top: 120px;
    }
    .paw-2{
      top: 110px;
      left: 50px;
    }
    .tail{
      background-color: #242424;
      width: 100px;
      height: 20px;
      position: relative;
      top: 80px;
      left: 100px;
      border-radius: 0 20px 20px 0;
    }
    
  
     /* the loading cat Animation */

    @keyframes rotateSmallerCat {
      from {
        transform: scale(0.5) rotate(360deg);
      }
      to {
        transform: scale(0.5) rotate(0deg);
        
      }
    }
  
  
    /* The star background */
  .stars {
    z-index: -2;
    position: fixed;
    top: 50%;
    left: 50%;
    height: 1px;
    width: 1px;
    border-radius: 50%;
    animation: moveStars 1.5s linear infinite;
    box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff, -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 
                29vw 19vh 0px 1px #fff, 4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff, 
                -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff, 4vw 22vh 1px 1px #fff, 
                36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff, -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 
                5vw 26vh 0px 0px #fff, -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff, 
                48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff, 43vw -27vh 1px 1px #fff, 
                20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff, 39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, 
                -40vw 45vh 0px 1px #fff, 11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff, 
                -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff, 42vw 24vh 0px 0px #fff, 
                45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff, -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 
                40vw 24vh 0px 0px #fff, 18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff, 
                27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff, -43vw 13vh 1px 0px #fff, 
                7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff, -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 
                38vw 25vh 1px 1px #fff, -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff, 
                48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff, 21vw 21vh 1px 1px #fff, 
                -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff, -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, 
                -48vw 25vh 0px 1px #fff, -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff, 
                3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff, 35vw -36vh 1px 1px #fff, 
                16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff, 3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, 
                -6vw 21vh 1px 0px #fff, 48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff, 
                -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff, 29vw -37vh 1px 1px #fff, 
                28vw 2vh 0px 0px #fff, -22vw 14vh 1px 0px #fff, 15vw -18vh 0px 1px #fff, -40vw -28vh 0px 0px #fff, -32vw -35vh 0px 1px #fff, 
                27vw 22vh 0px 1px #fff, 9vw -5vh 0px 1px #fff, -2vw 18vh 1px 1px #fff, -30vw 20vh 1px 1px #fff, 
                -42vw -5vh 1px 1px #fff, -25vw -37vh 0px 1px #fff, -38vw -21vh 0px 1px #fff, 7vw 25vh 1px 1px #fff, 
                33vw 19vh 0px 0px #fff, -19vw 30vh 1px 1px #fff, -14vw 3vh 1px 0px #fff, -14vw -10vh 0px 0px #fff, 
                3vw 20vh 0px 0px #fff, -31vw 38vh 0px 0px #fff, 3vw 40vh 1px 1px #fff, 14vw -17vh 1px 1px #fff, 
                45vw -10vh 1px 0px #fff, 24vw -20vh 0px 0px #fff, 42vw 45vh 0px 0px #fff, 39vw -30vh 1px 1px #fff, 
                18vw -3vh 0px 0px #fff, 11vw 20vh 0px 1px #fff, 36vw 45vh 1px 1px #fff, -18vw -8vh 0px 1px #fff, 
                -37vw 42vh 0px 1px #fff, 15vw -28vh 1px 0px #fff, 22vw 27vh 1px 0px #fff, 42vw -25vh 0px 1px #fff, 
                -3vw 15vh 0px 0px #fff, -25vw -40vh 1px 0px #fff, -4vw -20vh 1px 1px #fff, 39vw 20vh 0px 0px #fff, 
                42vw -43vh 1px 0px #fff, -34vw -15vh 0px 0px #fff, -41vw 10vh 0px 1px #fff, 33vw 13vh 0px 1px #fff, 
                38vw 20vh 0px 0px #fff, 20vw 8vh 0px 0px #fff, -13vw -18vh 1px 0px #fff, -21vw 45vh 0px 1px #fff, 
                25vw -40vh 1px 0px #fff, -4vw -20vh 0px 1px #fff, -12vw -22vh 0px 0px #fff, -38vw 10vh 1px 0px #fff, 
                10vw 23vh 1px 0px #fff, 43vw 7vh 0px 0px #fff, -41vw 13vh 1px 1px #fff, -2vw -25vh 0px 1px #fff, 
                35vw 23vh 1px 1px #fff, -36vw -4vh 1px 0px #fff, 8vw -2vh 1px 0px #fff, 45vw 16vh 0px 0px #fff, 
                45vw 12vh 0px 1px #fff, 16vw -25vh 0px 0px #fff, 6vw 10vh 0px 0px #fff, 23vw 18vh 1px 1px #fff, 
                -13vw -12vh 0px 1px #fff, -8vw -35vh 1px 0px #fff, -13vw 45vh 1px 1px #fff, -20vw 22vh 1px 1px #fff, 
                -45vw 22vh 0px 1px #fff, -28vw -16vh 0px 1px #fff, 7vw 34vh 1px 1px #fff, -40vw 25vh 0px 0px #fff, 
                6vw -22vh 0px 1px #fff, -36vw 11vh 0px 1px #fff, -39vw 28vh 0px 1px #fff, 32vw -33vh 1px 1px #fff, 
                19vw 46vh 0px 0px #fff, 5vw 36vh 0px 0px #fff, 4vw -30vh 0px 1px #fff, -41vw -3vh 1px 0px #fff, 
                -5vw 18vh 1px 0px #fff, 45vw 8vh 1px 1px #fff, -39vw 28vh 1px 1px #fff, 26vw -10vh 1px 1px #fff, 
                -45vw 11vh 1px 0px #fff, -39vw 28vh 1px 1px #fff, 31vw 12vh 1px 1px #fff, 27vw -33vh 1px 1px #fff, 
                25vw 4vh 0px 0px #fff;;
  }
  .stars2 {
    z-index: -2;
    position: fixed;
    top: 50%;
    left: 50%;
    height: 1px;
    width: 1px;
    animation: moveStars 1s linear infinite;
    animation-delay: 0.5s;
    box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff, -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 
                29vw 19vh 0px 1px #fff, 4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff, 
                -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff, 4vw 22vh 1px 1px #fff, 
                36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff, -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 
                5vw 26vh 0px 0px #fff, -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff, 
                48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff, 43vw -27vh 1px 1px #fff, 
                20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff, 39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, 
                -40vw 45vh 0px 1px #fff, 11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff, 
                -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff, 42vw 24vh 0px 0px #fff, 
                45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff, -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 
                40vw 24vh 0px 0px #fff, 18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff, 
                27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff, -43vw 13vh 1px 0px #fff, 
                7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff, -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 
                38vw 25vh 1px 1px #fff, -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff, 
                48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff, 21vw 21vh 1px 1px #fff, 
                -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff, -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, 
                -48vw 25vh 0px 1px #fff, -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff, 
                3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff, 35vw -36vh 1px 1px #fff, 
                16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff, 3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, 
                -6vw 21vh 1px 0px #fff, 48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff, 
                -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff, 29vw -37vh 1px 1px #fff, 
                28vw 2vh 0px 0px #fff, -22vw 14vh 1px 0px #fff, 15vw -18vh 0px 1px #fff, -40vw -28vh 0px 0px #fff, -32vw -35vh 0px 1px #fff, 
                27vw 22vh 0px 1px #fff, 9vw -5vh 0px 1px #fff, -2vw 18vh 1px 1px #fff, -30vw 20vh 1px 1px #fff, 
                -42vw -5vh 1px 1px #fff, -25vw -37vh 0px 1px #fff, -38vw -21vh 0px 1px #fff, 7vw 25vh 1px 1px #fff, 
                33vw 19vh 0px 0px #fff, -19vw 30vh 1px 1px #fff, -14vw 3vh 1px 0px #fff, -14vw -10vh 0px 0px #fff, 
                3vw 20vh 0px 0px #fff, -31vw 38vh 0px 0px #fff, 3vw 40vh 1px 1px #fff, 14vw -17vh 1px 1px #fff, 
                45vw -10vh 1px 0px #fff, 24vw -20vh 0px 0px #fff, 42vw 45vh 0px 0px #fff, 39vw -30vh 1px 1px #fff, 
                18vw -3vh 0px 0px #fff, 11vw 20vh 0px 1px #fff, 36vw 45vh 1px 1px #fff, -18vw -8vh 0px 1px #fff, 
                -37vw 42vh 0px 1px #fff, 15vw -28vh 1px 0px #fff, 22vw 27vh 1px 0px #fff, 42vw -25vh 0px 1px #fff, 
                -3vw 15vh 0px 0px #fff, -25vw -40vh 1px 0px #fff, -4vw -20vh 1px 1px #fff, 39vw 20vh 0px 0px #fff, 
                42vw -43vh 1px 0px #fff, -34vw -15vh 0px 0px #fff, -41vw 10vh 0px 1px #fff, 33vw 13vh 0px 1px #fff, 
                38vw 20vh 0px 0px #fff, 20vw 8vh 0px 0px #fff, -13vw -18vh 1px 0px #fff, -21vw 45vh 0px 1px #fff, 
                25vw -40vh 1px 0px #fff, -4vw -20vh 0px 1px #fff, -12vw -22vh 0px 0px #fff, -38vw 10vh 1px 0px #fff, 
                10vw 23vh 1px 0px #fff, 43vw 7vh 0px 0px #fff, -41vw 13vh 1px 1px #fff, -2vw -25vh 0px 1px #fff, 
                35vw 23vh 1px 1px #fff, -36vw -4vh 1px 0px #fff, 8vw -2vh 1px 0px #fff, 45vw 16vh 0px 0px #fff, 
                45vw 12vh 0px 1px #fff, 16vw -25vh 0px 0px #fff, 6vw 10vh 0px 0px #fff, 23vw 18vh 1px 1px #fff, 
                -13vw -12vh 0px 1px #fff, -8vw -35vh 1px 0px #fff, -13vw 45vh 1px 1px #fff, -20vw 22vh 1px 1px #fff, 
                -45vw 22vh 0px 1px #fff, -28vw -16vh 0px 1px #fff, 7vw 34vh 1px 1px #fff, -40vw 25vh 0px 0px #fff, 
                6vw -22vh 0px 1px #fff, -36vw 11vh 0px 1px #fff, -39vw 28vh 0px 1px #fff, 32vw -33vh 1px 1px #fff, 
                19vw 46vh 0px 0px #fff, 5vw 36vh 0px 0px #fff, 4vw -30vh 0px 1px #fff, -41vw -3vh 1px 0px #fff, 
                -5vw 18vh 1px 0px #fff, 45vw 8vh 1px 1px #fff, -39vw 28vh 1px 1px #fff, 26vw -10vh 1px 1px #fff, 
                -45vw 11vh 1px 0px #fff, -39vw 28vh 1px 1px #fff, 31vw 12vh 1px 1px #fff, 27vw -33vh 1px 1px #fff, 
                25vw 4vh 0px 0px #fff;;
  }
  .stars3 {
    z-index: -2;
    position: fixed;
    top: 50%;
    left: 50%;
    height: 1px;
    width: 1px;
    animation: moveStars 2s linear infinite;
    animation-delay: 1s;
    box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff, -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 
                29vw 19vh 0px 1px #fff, 4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff, 
                -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff, 4vw 22vh 1px 1px #fff, 
                36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff, -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 
                5vw 26vh 0px 0px #fff, -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff, 
                48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff, 43vw -27vh 1px 1px #fff, 
                20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff, 39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, 
                -40vw 45vh 0px 1px #fff, 11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff, 
                -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff, 42vw 24vh 0px 0px #fff, 
                45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff, -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 
                40vw 24vh 0px 0px #fff, 18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff, 
                27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff, -43vw 13vh 1px 0px #fff, 
                7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff, -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 
                38vw 25vh 1px 1px #fff, -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff, 
                48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff, 21vw 21vh 1px 1px #fff, 
                -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff, -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, 
                -48vw 25vh 0px 1px #fff, -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff, 
                3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff, 35vw -36vh 1px 1px #fff, 
                16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff, 3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, 
                -6vw 21vh 1px 0px #fff, 48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff, 
                -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff, 29vw -37vh 1px 1px #fff, 
                28vw 2vh 0px 0px #fff, -22vw 14vh 1px 0px #fff, 15vw -18vh 0px 1px #fff, -40vw -28vh 0px 0px #fff, -32vw -35vh 0px 1px #fff, 
                27vw 22vh 0px 1px #fff, 9vw -5vh 0px 1px #fff, -2vw 18vh 1px 1px #fff, -30vw 20vh 1px 1px #fff, 
                -42vw -5vh 1px 1px #fff, -25vw -37vh 0px 1px #fff, -38vw -21vh 0px 1px #fff, 7vw 25vh 1px 1px #fff, 
                33vw 19vh 0px 0px #fff, -19vw 30vh 1px 1px #fff, -14vw 3vh 1px 0px #fff, -14vw -10vh 0px 0px #fff, 
                3vw 20vh 0px 0px #fff, -31vw 38vh 0px 0px #fff, 3vw 40vh 1px 1px #fff, 14vw -17vh 1px 1px #fff, 
                45vw -10vh 1px 0px #fff, 24vw -20vh 0px 0px #fff, 42vw 45vh 0px 0px #fff, 39vw -30vh 1px 1px #fff, 
                18vw -3vh 0px 0px #fff, 11vw 20vh 0px 1px #fff, 36vw 45vh 1px 1px #fff, -18vw -8vh 0px 1px #fff, 
                -37vw 42vh 0px 1px #fff, 15vw -28vh 1px 0px #fff, 22vw 27vh 1px 0px #fff, 42vw -25vh 0px 1px #fff, 
                -3vw 15vh 0px 0px #fff, -25vw -40vh 1px 0px #fff, -4vw -20vh 1px 1px #fff, 39vw 20vh 0px 0px #fff, 
                42vw -43vh 1px 0px #fff, -34vw -15vh 0px 0px #fff, -41vw 10vh 0px 1px #fff, 33vw 13vh 0px 1px #fff, 
                38vw 20vh 0px 0px #fff, 20vw 8vh 0px 0px #fff, -13vw -18vh 1px 0px #fff, -21vw 45vh 0px 1px #fff, 
                25vw -40vh 1px 0px #fff, -4vw -20vh 0px 1px #fff, -12vw -22vh 0px 0px #fff, -38vw 10vh 1px 0px #fff, 
                10vw 23vh 1px 0px #fff, 43vw 7vh 0px 0px #fff, -41vw 13vh 1px 1px #fff, -2vw -25vh 0px 1px #fff, 
                35vw 23vh 1px 1px #fff, -36vw -4vh 1px 0px #fff, 8vw -2vh 1px 0px #fff, 45vw 16vh 0px 0px #fff, 
                45vw 12vh 0px 1px #fff, 16vw -25vh 0px 0px #fff, 6vw 10vh 0px 0px #fff, 23vw 18vh 1px 1px #fff, 
                -13vw -12vh 0px 1px #fff, -8vw -35vh 1px 0px #fff, -13vw 45vh 1px 1px #fff, -20vw 22vh 1px 1px #fff, 
                -45vw 22vh 0px 1px #fff, -28vw -16vh 0px 1px #fff, 7vw 34vh 1px 1px #fff, -40vw 25vh 0px 0px #fff, 
                6vw -22vh 0px 1px #fff, -36vw 11vh 0px 1px #fff, -39vw 28vh 0px 1px #fff, 32vw -33vh 1px 1px #fff, 
                19vw 46vh 0px 0px #fff, 5vw 36vh 0px 0px #fff, 4vw -30vh 0px 1px #fff, -41vw -3vh 1px 0px #fff, 
                -5vw 18vh 1px 0px #fff, 45vw 8vh 1px 1px #fff, -39vw 28vh 1px 1px #fff, 26vw -10vh 1px 1px #fff, 
                -45vw 11vh 1px 0px #fff, -39vw 28vh 1px 1px #fff, 31vw 12vh 1px 1px #fff, 27vw -33vh 1px 1px #fff, 
                25vw 4vh 0px 0px #fff;;
  }
  .stars4 {
    z-index: -5;
    position: fixed;
    top: 50%;
    left: 50%;
    height: 1px;
    width: 1px;
    animation: moveStars 1.5s linear infinite;
    animation-delay: 1.5s;
    box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff, -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 
                29vw 19vh 0px 1px #fff, 4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff, 
                -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff, 4vw 22vh 1px 1px #fff, 
                36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff, -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 
                5vw 26vh 0px 0px #fff, -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff, 
                48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff, 43vw -27vh 1px 1px #fff, 
                20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff, 39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, 
                -40vw 45vh 0px 1px #fff, 11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff, 
                -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff, 42vw 24vh 0px 0px #fff, 
                45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff, -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 
                40vw 24vh 0px 0px #fff, 18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff, 
                27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff, -43vw 13vh 1px 0px #fff, 
                7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff, -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 
                38vw 25vh 1px 1px #fff, -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff, 
                48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff, 21vw 21vh 1px 1px #fff, 
                -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff, -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, 
                -48vw 25vh 0px 1px #fff, -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff, 
                3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff, 35vw -36vh 1px 1px #fff, 
                16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff, 3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, 
                -6vw 21vh 1px 0px #fff, 48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff, 
                -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff, 29vw -37vh 1px 1px #fff, 
                28vw 2vh 0px 0px #fff, -22vw 14vh 1px 0px #fff, 15vw -18vh 0px 1px #fff, -40vw -28vh 0px 0px #fff, -32vw -35vh 0px 1px #fff, 
                27vw 22vh 0px 1px #fff, 9vw -5vh 0px 1px #fff, -2vw 18vh 1px 1px #fff, -30vw 20vh 1px 1px #fff, 
                -42vw -5vh 1px 1px #fff, -25vw -37vh 0px 1px #fff, -38vw -21vh 0px 1px #fff, 7vw 25vh 1px 1px #fff, 
                33vw 19vh 0px 0px #fff, -19vw 30vh 1px 1px #fff, -14vw 3vh 1px 0px #fff, -14vw -10vh 0px 0px #fff, 
                3vw 20vh 0px 0px #fff, -31vw 38vh 0px 0px #fff, 3vw 40vh 1px 1px #fff, 14vw -17vh 1px 1px #fff, 
                45vw -10vh 1px 0px #fff, 24vw -20vh 0px 0px #fff, 42vw 45vh 0px 0px #fff, 39vw -30vh 1px 1px #fff, 
                18vw -3vh 0px 0px #fff, 11vw 20vh 0px 1px #fff, 36vw 45vh 1px 1px #fff, -18vw -8vh 0px 1px #fff, 
                -37vw 42vh 0px 1px #fff, 15vw -28vh 1px 0px #fff, 22vw 27vh 1px 0px #fff, 42vw -25vh 0px 1px #fff, 
                -3vw 15vh 0px 0px #fff, -25vw -40vh 1px 0px #fff, -4vw -20vh 1px 1px #fff, 39vw 20vh 0px 0px #fff, 
                42vw -43vh 1px 0px #fff, -34vw -15vh 0px 0px #fff, -41vw 10vh 0px 1px #fff, 33vw 13vh 0px 1px #fff, 
                38vw 20vh 0px 0px #fff, 20vw 8vh 0px 0px #fff, -13vw -18vh 1px 0px #fff, -21vw 45vh 0px 1px #fff, 
                25vw -40vh 1px 0px #fff, -4vw -20vh 0px 1px #fff, -12vw -22vh 0px 0px #fff, -38vw 10vh 1px 0px #fff, 
                10vw 23vh 1px 0px #fff, 43vw 7vh 0px 0px #fff, -41vw 13vh 1px 1px #fff, -2vw -25vh 0px 1px #fff, 
                35vw 23vh 1px 1px #fff, -36vw -4vh 1px 0px #fff, 8vw -2vh 1px 0px #fff, 45vw 16vh 0px 0px #fff, 
                45vw 12vh 0px 1px #fff, 16vw -25vh 0px 0px #fff, 6vw 10vh 0px 0px #fff, 23vw 18vh 1px 1px #fff, 
                -13vw -12vh 0px 1px #fff, -8vw -35vh 1px 0px #fff, -13vw 45vh 1px 1px #fff, -20vw 22vh 1px 1px #fff, 
                -45vw 22vh 0px 1px #fff, -28vw -16vh 0px 1px #fff, 7vw 34vh 1px 1px #fff, -40vw 25vh 0px 0px #fff, 
                6vw -22vh 0px 1px #fff, -36vw 11vh 0px 1px #fff, -39vw 28vh 0px 1px #fff, 32vw -33vh 1px 1px #fff, 
                19vw 46vh 0px 0px #fff, 5vw 36vh 0px 0px #fff, 4vw -30vh 0px 1px #fff, -41vw -3vh 1px 0px #fff, 
                -5vw 18vh 1px 0px #fff, 45vw 8vh 1px 1px #fff, -39vw 28vh 1px 1px #fff, 26vw -10vh 1px 1px #fff, 
                -45vw 11vh 1px 0px #fff, -39vw 28vh 1px 1px #fff, 31vw 12vh 1px 1px #fff, 27vw -33vh 1px 1px #fff, 
                25vw 4vh 0px 0px #fff;;
  }
  .stars5 {
    z-index: -5;
    position: fixed;
    top: 50%;
    left: 50%;
    height: 1px;
    width: 1px;
    box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff, -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 
                29vw 19vh 0px 1px #fff, 4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff, 
                -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff, 4vw 22vh 1px 1px #fff, 
                36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff, -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 
                5vw 26vh 0px 0px #fff, -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff, 
                48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff, 43vw -27vh 1px 1px #fff, 
                20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff, 39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, 
                -40vw 45vh 0px 1px #fff, 11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff, 
                -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff, 42vw 24vh 0px 0px #fff, 
                45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff, -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 
                40vw 24vh 0px 0px #fff, 18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff, 
                27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff, -43vw 13vh 1px 0px #fff, 
                7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff, -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 
                38vw 25vh 1px 1px #fff, -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff, 
                48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff, 21vw 21vh 1px 1px #fff, 
                -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff, -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, 
                -48vw 25vh 0px 1px #fff, -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff, 
                3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff, 35vw -36vh 1px 1px #fff, 
                16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff, 3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, 
                -6vw 21vh 1px 0px #fff, 48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff, 
                -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff, 29vw -37vh 1px 1px #fff, 
                28vw 2vh 0px 0px #fff, -22vw 14vh 1px 0px #fff, 15vw -18vh 0px 1px #fff, -40vw -28vh 0px 0px #fff, -32vw -35vh 0px 1px #fff, 
                27vw 22vh 0px 1px #fff, 9vw -5vh 0px 1px #fff, -2vw 18vh 1px 1px #fff, -30vw 20vh 1px 1px #fff, 
                -42vw -5vh 1px 1px #fff, -25vw -37vh 0px 1px #fff, -38vw -21vh 0px 1px #fff, 7vw 25vh 1px 1px #fff, 
                33vw 19vh 0px 0px #fff, -19vw 30vh 1px 1px #fff, -14vw 3vh 1px 0px #fff, -14vw -10vh 0px 0px #fff, 
                3vw 20vh 0px 0px #fff, -31vw 38vh 0px 0px #fff, 3vw 40vh 1px 1px #fff, 14vw -17vh 1px 1px #fff, 
                45vw -10vh 1px 0px #fff, 24vw -20vh 0px 0px #fff, 42vw 45vh 0px 0px #fff, 39vw -30vh 1px 1px #fff, 
                18vw -3vh 0px 0px #fff, 11vw 20vh 0px 1px #fff, 36vw 45vh 1px 1px #fff, -18vw -8vh 0px 1px #fff, 
                -37vw 42vh 0px 1px #fff, 15vw -28vh 1px 0px #fff, 22vw 27vh 1px 0px #fff, 42vw -25vh 0px 1px #fff, 
                -3vw 15vh 0px 0px #fff, -25vw -40vh 1px 0px #fff, -4vw -20vh 1px 1px #fff, 39vw 20vh 0px 0px #fff, 
                42vw -43vh 1px 0px #fff, -34vw -15vh 0px 0px #fff, -41vw 10vh 0px 1px #fff, 33vw 13vh 0px 1px #fff, 
                38vw 20vh 0px 0px #fff, 20vw 8vh 0px 0px #fff, -13vw -18vh 1px 0px #fff, -21vw 45vh 0px 1px #fff, 
                25vw -40vh 1px 0px #fff, -4vw -20vh 0px 1px #fff, -12vw -22vh 0px 0px #fff, -38vw 10vh 1px 0px #fff, 
                10vw 23vh 1px 0px #fff, 43vw 7vh 0px 0px #fff, -41vw 13vh 1px 1px #fff, -2vw -25vh 0px 1px #fff, 
                35vw 23vh 1px 1px #fff, -36vw -4vh 1px 0px #fff, 8vw -2vh 1px 0px #fff, 45vw 16vh 0px 0px #fff, 
                45vw 12vh 0px 1px #fff, 16vw -25vh 0px 0px #fff, 6vw 10vh 0px 0px #fff, 23vw 18vh 1px 1px #fff, 
                -13vw -12vh 0px 1px #fff, -8vw -35vh 1px 0px #fff, -13vw 45vh 1px 1px #fff, -20vw 22vh 1px 1px #fff, 
                -45vw 22vh 0px 1px #fff, -28vw -16vh 0px 1px #fff, 7vw 34vh 1px 1px #fff, -40vw 25vh 0px 0px #fff, 
                6vw -22vh 0px 1px #fff, -36vw 11vh 0px 1px #fff, -39vw 28vh 0px 1px #fff, 32vw -33vh 1px 1px #fff, 
                19vw 46vh 0px 0px #fff, 5vw 36vh 0px 0px #fff, 4vw -30vh 0px 1px #fff, -41vw -3vh 1px 0px #fff, 
                -5vw 18vh 1px 0px #fff, 45vw 8vh 1px 1px #fff, -39vw 28vh 1px 1px #fff, 26vw -10vh 1px 1px #fff, 
                -45vw 11vh 1px 0px #fff, -39vw 28vh 1px 1px #fff, 31vw 12vh 1px 1px #fff, 27vw -33vh 1px 1px #fff, 
                25vw 4vh 0px 0px #fff;;
  }
  
  /* The Stars Moving animation */
  @keyframes moveStars {
    from {
        transform: scale(1);
        opacity: 0;
  
    }
    to {
        transform: scale(5);
        opacity: 0.8;
  
    }
  }

  /* the cats their container */
  .catcontainer {
    left: -10vw;
    position: absolute;
    animation: moveRight 6s infinite linear, changeTop 12s infinite linear;
    z-index: -4;
  }
  .catcontainer2 {
    left: -50vw;
    position: absolute;
    animation: moveRight2 9.5s infinite linear, changeTop2 19s infinite linear;
    animation-delay: 1s;
    z-index: -2;
  
  }
  .catcontainer3 {
    left: -30vw;
    position: absolute;
    animation: moveRight3 15s infinite linear, changeTop3 24s infinite linear;
    animation-delay: 2s;
    z-index: -3;
  
  }
  
  

  /* for the cats to move to the right */
  @keyframes moveRight {
    from {
      transform: translateX(-20vw);
    }
    to {
      transform: translateX(150vw);
    }
  }
  @keyframes moveRight2 {
    from {
      transform: translateX(-10vw);
    }
    to {
      transform: translateX(150vw);
    }
  }
  @keyframes moveRight3 {
    from {
      transform: translateX(-20vw);
    }
    to {
      transform: translateX(150vw);
    }
  }
  
  /* For them(meows) to fly arround*/
  @keyframes changeTop {
    0%, 25% {
      top: 0;
    }
    50% {
      top: 25vh;
    }
    75%, 100% {
      top: 50vh;
    }
  }
  @keyframes changeTop2 {
    0%, 20% {
      top: 5vh;
    }
    40% {
      top: -15vh;
    }
    60% {
      top: 40vh;
    }
    80%, 100% {
      top: -20vh;
    }
  }
  @keyframes changeTop3 {
    0%, 16.66% {
      top: 20vh;
    }
    33.33% {
      top: -20vh;
    }
    50% {
      top: 60vh;
    }
    66.66% {
      top: -60vh;
    }
    83.33%, 100% {
      top: -40vh;
    }
  }
  
  /* the cats in the background */
  .cat2 {
    z-index: -5;
    position:absolute;
    left: -10vw;
    animation: rotateCatSpace 1.5s linear infinite; 
  }
  .cat3 {
    z-index: -6;
    position:absolute;
    left: -10vw;
    animation: rotateCatSpace 1.5s linear infinite; 
  
  }
  .cat4 {
    z-index: -7;
    position:absolute;
    left: -10vw;
    animation: rotateCatSpace 1.5s linear infinite;
   
  }
  
  /* for the cats to rotate and for them to be smaller */
  @keyframes rotateCatSpace {
    0% {
      transform: rotate(360deg) scale(0.3);
    }
    100% {
      transform: rotate(0deg) scale(0.3);  }
  
  }
  
  
    /* the Cat Designs*/

  /* cat2 Design */
  .face_3{
    background-color: white;
    height: 100px;
    width: 150px;
    border-radius: 30px;
  }
  .ear-1_3,.ear-r_3{
  
    width: 0;
    height: 0;
    border-bottom: 50px solid white;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    position: relative;
    z-index: -1;
  }
  .ear-1_3{
    bottom: 35px;
    transform: rotate(-30deg);
  }
  .ear-r_3{
    bottom: 85px;
    left: 110px;
    transform: rotate(30deg);
  }
  .inner-1_3{
    width: 0;
    height: 0;
    border-bottom: 50px solid #d36149;
    border-left: 25px solid transparent;
    right: 25px;
    position: relative;  
  }
  .inner-2_3{
    width: 0;
    height: 0;
    border-bottom: 50px solid #d36149;
    border-right: 25px solid transparent;
    position: relative;
  }
  .eye-1_3,.eye-r_3{
    background-color: gray;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: relative; 
  }
  .eye-1_3{
    bottom: 75px;
    left: 30px;
  }
  .eye-r_3{
    bottom: 105px;
    left: 90px;
  }
  .eyeball_3{
    background-color: lightcyan;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: relative;
    top: 3px;
    left: 3px;
  }
  .nose_3{
    height: 0;
    width: 0;
    border-top: 8px solid #d36149;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: relative;
    bottom: 110px;
    left: 68px;  
  }
  .l1_3,.l2_3{
    background-color: #d36149;
    height: 8px;
    width: 1px;
    position: relative;
  }
  .l1_3{
    transform: rotate(45deg);
    right: 4px;
    bottom: 1.5px;  
  }
  .l2_3{
    transform: rotate(-45deg);
    bottom: 10px;
    left: 3px;  
  }
  .body_3{
    background-color: white;
    height: 120px;
    width: 150px;
    position: relative;
    left: 30px;
    border: 5px;
    border-radius: 0 65px 0 0;
  }
  .paw-1_3,.paw-2_3{
    background-color: white;
    height: 10px;
    width: 22px;
    position: relative;
    border-radius: 0 0 10px 10px;
  }
  .paw-1_3{
    top: 120px;
  }
  .paw-2_3{
    top: 110px;
    left: 50px;
  }
  .tail_3{
    background-color: white;
    width: 100px;
    height: 20px;
    position: relative;
    top: 80px;
    left: 100px;
    border-radius: 0 20px 20px 0;
  }
  
  /* Cat3 Design*/
  .face_4{
    background-color: burlywood;
    height: 100px;
    width: 150px;
    border-radius: 30px;
  }
  .ear-1_4,.ear-r_4{
    width: 0;
    height: 0;
    border-bottom: 50px solid burlywood;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    position: relative;
    z-index: -1;
  }
  .ear-1_4{
    bottom: 35px;
    transform: rotate(-30deg);
  }
  .ear-r_4{
    bottom: 85px;
    left: 110px;
    transform: rotate(30deg);
  }
  .inner-1_4{
    width: 0;
    height: 0;
    border-bottom: 50px solid #d36149;
    border-left: 25px solid transparent;
    right: 25px;
    position: relative;  
  }
  .inner-2_4{
    width: 0;
    height: 0;
    border-bottom: 50px solid #d36149;
    border-right: 25px solid transparent;
    position: relative;
  }
  .eye-1_4,.eye-r_4{
    background-color: lightgreen;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: relative; 
  }
  .eye-1_4{
    bottom: 75px;
    left: 30px;
  }
  .eye-r_4{
    bottom: 105px;
    left: 90px;
  }
  .eyeball_4{
    background-color: black;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: relative;
    top: 3px;
    left: 3px;
  }
  .nose_4{
    height: 0;
    width: 0;
    border-top: 8px solid #d36149;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: relative;
    bottom: 110px;
    left: 68px;  
  }
  .l1_4,.l2_4{
    background-color: burlywood;
    height: 8px;
    width: 1px;
    position: relative;
  }
  .l1_4{
    transform: rotate(45deg);
    right: 4px;
    bottom: 1.5px;  
  }
  .l2_4{
    transform: rotate(-45deg);
    bottom: 10px;
    left: 3px;  
  }
  .body_4{
    background-color: burlywood;
    height: 120px;
    width: 150px;
    position: relative;
    left: 30px;
    border: 5px;
    border-radius: 0 65px 0 0;
  }
  .paw-1_4,.paw-2_4{
    background-color: burlywood;
    height: 10px;
    width: 22px;
    position: relative;
    border-radius: 0 0 10px 10px;
  }
  .paw-1_4{
    top: 120px;
  }
  .paw-2_4{
    top: 110px;
    left: 50px;
  }
  .tail_4{
    background-color: burlywood;
    width: 100px;
    height: 20px;
    position: relative;
    top: 80px;
    left: 100px;
    border-radius: 0 20px 20px 0;
  }
  
  
  
  
  /* The selections no explanation needed ig*/
  .selections {
    z-index: -3;
    display: flex;
    align-items: center;
    width: 80vw;
    transition: all ease-in-out 0.3s;
    text-align: center;
    margin: 0 auto; /* Center the container */
    justify-content: center; /* Align items horizontally */
    flex-wrap: wrap; /* Allow wrapping if needed */
  }
.selection {
  margin-top: 3%;
  margin-right: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 25%;
  padding-left: 1%;
  padding-right: 1%;
  text-align: center;
  height: 40vh;
  font-size: 25px;
  color: rgb(212, 212, 212);
  background: rgba(24, 24, 24, 0.534);
  background: linear-gradient(to right, #1111118e, #3838389a 33%, #383838a4 66%, #111111a1);
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.151);
  background-size: cover;
  background-position: center;
  border: solid 2px rgba(0, 0, 0, 0.37);
  filter: grayscale(0);
}
.selection:hover {
  cursor: pointer;
  transform: scale(1.01);
  transform: translateY(-10px);

}
.selection:nth-child(1) {
  background-image: url('../pics/pussys.jpg');
}

.selection:nth-child(2) {
  background-image: url('../pics/pic.jpg');
}

.selection:nth-child(3) {
  background-image: url('../pics/gamingcat.jpg');
}
.animated-button {
  left: 1.5vw;
  top: 95%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5% 15%;
  border: 0px solid;
  border-color: transparent;
  font-size: 15px;
  background-color: rgba(26, 26, 26, 0.849);
  border-radius: 100px;
  color: rgb(162, 242, 252);
  box-shadow: 0 0 0 2px rgb(162, 242, 252);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  font-family: 'Minecraft', sans-serif;

}

.animated-button svg {
  position: absolute;
  width: 20px;
  fill: rgb(162, 242, 252);
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}


.animated-button .arr-2 {
  left: -25%;
}

.animated-button .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: rgb(162, 242, 252);
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.selection:hover .animated-button {
  box-shadow: 0 0 0 12px transparent;
  color: #212121;
  border-radius: 12px;
}


.selection:hover .animated-button .arr-2 {
  left: 16px;
}

.selection:hover .animated-button .text {
  transform: translateX(12px);
}

.selection:hover .animated-button svg {
  fill: #212121;
}

.animated-button:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px rgb(162, 242, 252);
}

.selection:hover .animated-button .circle {
  width: 220px;
  height: 220px;
  opacity: 1;
}

@media (max-width: 600px) {
  .animated-button {
    left: -1vw;
    margin-right: 2.5vw;
    top: 98%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 5% 30%;
    border: 0px solid;
    border-color: transparent;
    font-size: 12px;
    background-color: rgba(26, 26, 26, 0.849);
    border-radius: 100px;
    color: rgb(162, 242, 252);
    box-shadow: 0 0 0 2px rgb(162, 242, 252);
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    font-family: 'Minecraft', sans-serif;
  
  }
  
  .animated-button svg {
    position: absolute;
    width: 15px;
    fill: rgb(162, 242, 252);
    z-index: 9;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button .arr-2 {
    left: -25%;
  }
  
  .animated-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: rgb(162, 242, 252);
    border-radius: 50%;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button .text {
    position: relative;
    z-index: 1;
    transform: translateX(0px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .selection:hover .animated-button {
    box-shadow: 0 0 0 12px transparent;
    color: #212121;
    border-radius: 12px;
  }
  
  
  .selection:hover .animated-button .arr-2 {
    left: 16px;
  }
  
  .selection:hover .animated-button .text {
    transform: translateX(12px);
  }
  
  .selection:hover .animated-button svg {
    fill: #212121;
  }
  
  .animated-button:active {
    scale: 0.95;
    box-shadow: 0 0 0 4px rgb(162, 242, 252);
  }
  
  .selection:hover .animated-button .circle {
    width: 220px;
    height: 220px;
    opacity: 1;
  }

}









  /* The E-mail tooltip also no explanation needed ig */
.tooltip-container {
  top: 20vh; 
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70px;
  width: 110px;
  border-radius: 5px;
  background-color: rgb(35, 35, 35);
  background-image: linear-gradient(
    to left bottom,
    rgb(35, 35, 35),
    rgb(41, 41, 41),
    rgb(35, 35, 35),
    rgb(41, 41, 41),
    rgb(35, 35, 35)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.151);
  position: relative;
  transition: transform 0.3s ease;
}

.tooltip-container::before {
  position: absolute;
  content: "";
  top: -50%;
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
  border-radius: 5px;
  background-color: rgb(35, 35, 35);
  background-image: linear-gradient(
    to left bottom,
    rgb(35, 35, 35),
    rgb(41, 41, 41),
    rgb(35, 35, 35),
    rgb(41, 41, 41),
    rgb(35, 35, 35)
  );
  width: 100%;
  height: 50%;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateX(-150deg) translateY(-110%);
  transition: transform 0.3s ease;
}
.tooltip-container .text {
  
  color: rgb(65, 65, 65);
  font-weight: bold;
  font-size: 40px;
}
.tooltip {
  position: absolute;
  top: -20px;
  opacity: 0;
  color: rgb(177, 177, 177);
  background-size: 4px 4px, 4px 4px, 80px 80px, 80px 80px, 80px 80px, 80px 80px,
    80px 80px, 80px 80px;
  padding: 5px 10px;
  height: 70px;
  width: 110px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  pointer-events: none;
  letter-spacing: 0.5px;
  font-size: 18px;
  font-weight: 600;
  text-shadow: 10px salmon;
}
.tooltip-container:hover {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.tooltip-container:hover::before {
  transform: rotateY(0);
  background-image: none;
  background-color: rgb(35, 35, 35);
}
.tooltip-container:hover .tooltip {
  top: -80px;
  opacity: 1;
  transition-duration: 0.3s;
}
.hi {
color: rgb(65, 65, 65);
font-weight: bold;
margin-right: 10%;
}
.tooltip-container:hover .hi {
color: aqua;
font-weight: bold;
margin-right: 10%;
}



}